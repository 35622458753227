import { findNFTInfo } from "allIngredients";
import { useCombo } from "components/utils/ComboProvider";
import ingredientAddresses from "utils/ingredientAddresses";
import { combine } from "utils/sui";
import LoadingSpinner from "./utils/LoadingSpinner";

export const MintOverlay = () => {
  const {
    combo,
    setCombo,
    overlay,
    setOverlay,
    userIngredients,
    newIngredient,
    setNewIngredient,
    addUserIngredientFromNFT,
    userKiosk,
    loading,
    setLoading,
  } = useCombo();

  const handleClose = () => {
    setOverlay(false);
    setNewIngredient(null);
    setCombo([]);
  };

  const handleClick = async () => {
    const item1 = ingredientAddresses.find((i) => i.name === combo[0]);
    const item2 = ingredientAddresses.find((i) => i.name === combo[1]);
    const userItem1 = userIngredients.find((i) => i.data_id === item1.address);
    const userItem2 = userIngredients.find((i) => i.data_id === item2.address);
    const item3 = ingredientAddresses.find((i) => i.name === newIngredient.name);
    if (userItem1?.id && userItem2?.id && item3?.address) {
      setLoading(true);
      const docombine = await combine(
        userKiosk,
        userItem1.id,
        userItem2.id,
        item3.address
      );
      if (docombine.status === "success") {
        const newElement = findNFTInfo(item3);
        newElement.id = docombine.created.find(
          (a) => a.owner.ObjectOwner !== userKiosk.id
        ).reference.objectId;

        newElement.data_id = item3.address;
        addUserIngredientFromNFT(newElement);
        handleClose();
      }
    }
    setLoading(false);
  };

  return (
    <div className={`mint-overlay ${overlay ? "active-overlay" : ""}`}>
      <div className="rays-container">
        {newIngredient && (
          <>
            <div className="newIngredientName">{newIngredient.name}</div>
            <div className="rays" />
            <newIngredient.icon
              key={newIngredient.name}
              className={`${newIngredient.color} newIngredientIcon`}
              alt={newIngredient.name}
            />
          </>
        )}
      </div>
      <div className="mintButtonContainer">
        <button disabled={loading} className="mintButton" onClick={() => handleClick()}>
          {loading ? <LoadingSpinner /> : <span>Mint it!</span>}
        </button>
        <button disabled={loading} className="mintButton" onClick={() => handleClose()}>
          {loading ? <LoadingSpinner /> : <span>Never mind...</span>}
        </button>
      </div>
    </div>
  );
};

export default MintOverlay;
