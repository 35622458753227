import { useState, useEffect } from "react";
import { useCombo } from "components/utils/ComboProvider";
import useWindowSize from "./utils/useWindowSize";
import ReactTooltip from "react-tooltip";
import { findNFTInfo } from "allIngredients";
import * as sui from "utils/sui";
import LoadingSpinner from "./utils/LoadingSpinner";
import { ethos } from "ethos-connect";

const Ingredients = () => {
  const [detailedLayout, setDetailedLayout] = useState(false);
  const {
    combo,
    setCombo,
    userIngredients,
    setUserIngredients,
    userKiosk,
    setUserKiosk,
    loading,
    setLoading,
  } = useCombo();
  let userAddress = ethos.useAddress();
  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 1440) {
      if (detailedLayout) setDetailedLayout(false);
    }
  }, [width]);

  //button click will update combo array
  const handleUpdate = (data) => {
    if (combo.length < 2) {
      setCombo((combo) => [...combo, data.name]);
    }
  };

  const handleToggle = () => {
    setDetailedLayout(!detailedLayout);
  };

  const mintStarters = async () => {
    setLoading(true);
    const res = await sui.mintStarters(userKiosk);
    if (res.status === "success") {
      let { kiosk, inventory } = await sui.getUserNFTs(userAddress, userKiosk);
      inventory = findNFTInfo(inventory, true);
      setUserIngredients(inventory);
      setUserKiosk(kiosk);
    }
    setLoading(false);
  };

  if (userIngredients.length < 4) {
    return (
      <div className="ingredientContainer">
        <button disabled={loading} className="mintButton" onClick={() => mintStarters()}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <span>Click here to mint some basic elements</span>
          )}
        </button>
      </div>
    );
  }

  // takes the currentIngredients[] and maps over it to hydrate
  // our dom with <div><button><icon></button></div>
  return (
    <div className="ingredientContainer">
      <button onClick={handleToggle} className="menuButton">
        <span>{detailedLayout ? "Show Simple" : "Show Detailed"}</span>
      </button>

      {detailedLayout
        ? userIngredients.map((data) => (
            <button
              className={`ingredientDetailed ${
                combo.includes(data.name) ? "selectedIngredient" : ""
              }`}
              key={data.name}
              onClick={() => handleUpdate(data)}
            >
              <data.icon
                key={data.name}
                className={`${data.color} detailedIcon`}
                alt={data.name}
              />
              <span className="ingredientName">{data.name}</span>
            </button>
          ))
        : userIngredients.map((data) => (
            <div key={data.name}>
              <ReactTooltip id={data.name} type="success" effect="solid">
                <span className="ingredientHoverText">{data.name}</span>
              </ReactTooltip>
              <button
                data-tip
                data-for={data.name}
                data-tip-disable={!!(width < 600)}
                className={`ingredient ${
                  combo.includes(data.name) ? "selectedIngredient" : ""
                }`}
                key={data.name}
                onClick={() => handleUpdate(data)}
              >
                <data.icon
                  key={data.name}
                  className={`ingredientIcon ${data.color}`}
                  alt={data.name}
                />
              </button>
            </div>
          ))}
    </div>
  );
};

export default Ingredients;
