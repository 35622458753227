import { SignInButton, ethos, EthosConnectStatus } from "ethos-connect";
import { useState } from "react";
import { useEffect } from "react";
import { getObjectInfo, packageObjectId } from "utils/sui";

export const StartGameOverlay = ({ modal, setModal }) => {
  const wallet = ethos.useWallet();
  const [networkReset, setNetworkReset] = useState(true);

  useEffect(() => {
    getObjectInfo(packageObjectId).then((exists) => {
      if (exists) {
        // network reset!
        setNetworkReset(false);
      }
    });
  }, []);

  useEffect(() => {
    if (wallet?.status === EthosConnectStatus.Connected && !networkReset) {
      setModal(false);
    }
  }, [wallet, networkReset]);

  if (networkReset) {
    return (
      <div className={modal ? "modal" : "no-modal"}>
        <div className="modal-text">
          <span className="overlay-title">KEEPSAKE ALCHEMY</span>
          <span className="overlay-tagline">
            Network has been reset, please be patient while we redeploy Alchemy.
          </span>
        </div>
      </div>
    );
  }

  if (wallet.status !== EthosConnectStatus.Connected) {
    return (
      <div className={modal ? "modal" : "no-modal"}>
        <div className="modal-text">
          <span className="overlay-title">KEEPSAKE ALCHEMY</span>
          <SignInButton className="play-button">
            Connect with your Sui wallet to begin!
          </SignInButton>
          <span className="overlay-tagline">
            Combine ingredients, mint your discoveries, buy/sell/trade with other
            Alchemists on the Keepsake marketplace, and harness the powers of the
            elements!
          </span>
        </div>
      </div>
    );
  }

  return <div></div>;
};

export default StartGameOverlay;
