import { Combo } from "./Combo";

export const ComboContainer = () => {
  return (
    <div className="comboContainer">
      <div className="header">
        <span className="header-title">KEEPSAKE ALCHEMY</span>
      </div>
      <Combo />
    </div>
  );
};

export default ComboContainer;
