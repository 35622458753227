import KeepsakeBrand from "assets/images/KeepsakeBrand.png";
import { GiSecretBook } from "react-icons/gi";

export const Sidebar = () => {
  return (
    <div className="sidebar">
      <a href="https://keepsake.gg" target="_blank" className="keepsake-text">
        <img src={KeepsakeBrand} />
        <span>Buy, Sell, and Trade on Keepsake Marketplace</span>
      </a>
      {/* <div className="sidebarButton">
        <GiSecretBook className="sidebarIcon" />
        <span>Test</span>
      </div> */}
    </div>
  );
};

export default Sidebar;
