import { useCallback, useEffect, useState } from "react";
import { useCombo } from "components/utils/ComboProvider";
import { ingredientsArr } from "../allIngredients";
import AnimatedBox from "./AnimatedBox";

export const Combo = () => {
  const { combo, setCombo, userIngredients, setOverlay, setNewIngredient } = useCombo();
  const [firstIngredient, setFirstIngredient] = useState(null);
  const [secondIngredient, setSecondIngredient] = useState(null);
  const [badCombo, setBadCombo] = useState(false);

  const getIngredient = (name) => {
    return userIngredients.filter((entry) => entry.name === name);
  };

  const isSubset = (array1, array2) =>
    array1.every(
      (element) => array2.includes(element) && filterDuplicates(array1, array2, element)
    );

  const filterDuplicates = (array1, array2, val) =>
    array1.filter((el) => el === val).length === array2.filter((el) => el === val).length;

  const tryCombine = () => {
    const newIngredientIndex = ingredientsArr.findIndex((item) =>
      isSubset(combo, item.mix)
    );
    if (newIngredientIndex > 0) {
      const isNew = !userIngredients.find(
        (a) => a.name == ingredientsArr[newIngredientIndex].name
      );
      if (isNew) {
        setNewIngredient(ingredientsArr[newIngredientIndex]);
        // prompt to mint
        setOverlay(true);
      } else {
        resetCombo();
      }
    } else {
      resetCombo();
    }
  };

  const checkForMatch = useCallback(() => {
    if (combo.length === 2) {
      let timer = setTimeout(() => tryCombine(), 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [combo]);

  //checks combo vs ingredient.mix to see if valid ingredient
  useEffect(() => {
    checkForMatch();
  }, [checkForMatch]);

  const resetCombo = () => {
    setBadCombo(true);
    setCombo([]);
    let timer = setTimeout(() => setBadCombo(false), 500);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    if (combo.length > 0) {
      if (combo[0]) setFirstIngredient(getIngredient(combo[0])[0]);
      if (combo[1]) setSecondIngredient(getIngredient(combo[1])[0]);
    } else {
      setFirstIngredient(null);
      setSecondIngredient(null);
    }
  }, [combo]);

  return (
    <div className="comboBoxesContainer">
      <div className="flex-center">
        <div id="box" className={badCombo ? "shake" : ""}>
          <AnimatedBox
            className={badCombo ? "error reverse-anim" : "reverse-anim"}
            reverse
          />
          {firstIngredient && (
            <div className="puffIn flex-center">
              <firstIngredient.icon
                key={firstIngredient.name}
                className={`${firstIngredient.color} comboIcon`}
                alt={firstIngredient.name}
              />
            </div>
          )}
          <span className="comboName">{firstIngredient?.name}</span>
        </div>
        <div id="box" className={badCombo ? "reverse-shake" : ""}>
          <AnimatedBox className={badCombo ? "error" : ""} />
          {secondIngredient && (
            <div className="puffIn flex-center">
              <secondIngredient.icon
                key={secondIngredient.name}
                className={`${secondIngredient.color} comboIcon`}
                alt={secondIngredient.name}
              />
            </div>
          )}
          <span className="comboName">{secondIngredient?.name}</span>
        </div>
      </div>
    </div>
  );
};
