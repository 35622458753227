import React from "react";
import { createUseStyles } from "react-jss";

const AnimatedBox = ({ className = "", reverse }) => {
  const animationTime = 6000;
  const clipDistance = 0.05;
  const pathWidth = 2;
  const boxSize = 100;
  const clipSize = boxSize * (1 + clipDistance * 2);
  const pathClip = clipSize - pathWidth;
  const mainColor = "#69ca62";

  const useStyles = createUseStyles({
    "@keyframes clipMe": {
      "0%, 100%": {
        clip: `rect(0px, ${clipSize}px, ${pathWidth}px, 0px)`,
      },
      "25%": {
        clip: `rect(0px, ${pathWidth}px, ${clipSize}px, 0px)`,
      },
      "50%": {
        clip: `rect(${pathClip}px, ${clipSize}px, ${clipSize}px, 0px)`,
      },
      "75%": {
        clip: `rect(0px, ${clipSize}px, ${clipSize}px, ${pathClip}px)`,
      },
    },
    bb: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: boxSize,
      height: boxSize,
      margin: "auto",
      background: "rgba(0,0,0, 0.1)",
      color: mainColor,
      boxShadow: `inset 0 0 0 1px ${mainColor}80`,
      transition: "color .5s",
      transform:
        reverse === true
          ? `rotate(-45deg) scale(2) !important`
          : `rotate(45deg) scale(2) !important`,

      "&:before, &:after": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        content: '""',
        zIndex: 1,
        margin: -1 * clipDistance * 100 + "%",
        boxShadow: `inset 0 0 0 ${pathWidth}px`,
        animationName: "$clipMe",
        animationDuration: animationTime,
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      },
      "&:before": {
        animationDelay: animationTime * -0.5,
      },
      "@media screen and (min-width: 600px)": {
        bb: {
          width: "50px !important",
        },
      },
    },
    "@media (max-width: 1440px)": {
      bb: {
        transform:
          reverse === true
            ? `rotate(-45deg) scale(1) !important`
            : `rotate(45deg) scale(1) !important`,
      },
    },
  });

  const classes = useStyles();
  return <div className={`${classes.bb} ${className}`} />;
};

export default AnimatedBox;
