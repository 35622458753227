import {
  GiMetalBar,
  GiHatchet,
  GiWoodStick,
  GiKite,
  GiWoodPile,
  GiCrosscutSaw,
  GiWoodBeam,
  GiSpellBook,
  GiMountainCave,
  GiWoodCabin,
  GiGlassShot,
  GiFamilyHouse,
  GiMagnifyingGlass,
  GiMicroscope,
  GiFactory,
  GiIBeam,
  GiClothes,
  GiChemicalDrop,
  GiLightningArc,
  GiPlug,
  GiMedicines,
  GiSteamLocomotive,
  GiLightBulb,
  GiCaveman,
  GiFarmer,
  GiMineExplosion,
  GiCartwheel,
  GiRaceCar,
  GiMadScientist,
  GiMushroomCloud,
  GiMaterialsScience,
  GiAngelOutfit,
  GiCampfire,
  GiGroundSprout,
  GiStripedSun,
  GiSteam,
  GiWaterDrop,
  GiIceCube,
  GiHeavyRain,
  GiTornado,
  GiSnowing,
  GiWheat,
} from "react-icons/gi";
import { MdOutlineFamilyRestroom } from "react-icons/md";
import { FcLibrary } from "react-icons/fc";
import { IoThunderstormSharp } from "react-icons/io5";
import { HiFire } from "react-icons/hi";
import { WiWindy } from "react-icons/wi";
import { BsWind, BsFileText, BsBuilding, BsTools } from "react-icons/bs";
import { FaMountain, FaSchool, FaTree, FaBacteria } from "react-icons/fa";

export const findNFTInfo = (nfts) => {
  let wasArray = Array.isArray(nfts);
  let foundNFTs = false;
  if (!wasArray) {
    if (!nfts) {
      return [];
    }
    foundNFTs = [nfts].slice();
  } else {
    foundNFTs = nfts.slice();
  }

  foundNFTs
    .filter((nft) => nft)
    .forEach((nft, index) => {
      let data = ingredientsArr.find((a) => a.name == nft.name);
      foundNFTs[index] = { ...foundNFTs[index], ...data };
    });

  foundNFTs = sortNFTs(foundNFTs.filter((a) => a.icon));
  if (!wasArray) return foundNFTs[0];
  return foundNFTs;
};

export const sortNFTs = (nftArray) =>
  nftArray.sort((a, b) => {
    return findIndexByName(a.name) - findIndexByName(b.name);
  });

const findIndexByName = (name) => ingredientsArr.findIndex((a) => a.name == name);

export const ingredientsArr = [
  {
    name: "fire",
    icon: HiFire,
    color: "red",
    mix: [],
  },
  {
    name: "water",
    icon: GiWaterDrop,
    color: "blue",
    mix: [],
  },
  {
    name: "air",
    icon: WiWindy,
    color: "white",
    mix: [],
  },
  {
    name: "earth",
    icon: GiGroundSprout,
    color: "brown",
    mix: [],
  },
  {
    name: "steam",
    icon: GiSteam,
    color: "white",
    mix: ["fire", "water"],
  },
  {
    name: "ice",
    icon: GiIceCube,
    color: "blue",
    mix: ["air", "water"],
  },
  {
    name: "mountain",
    icon: FaMountain,
    color: "blue-gray",
    mix: ["earth", "earth"],
  },
  {
    name: "rain",
    icon: GiHeavyRain,
    color: "raining",
    mix: ["water", "water"],
  },
  {
    name: "snow",
    icon: GiSnowing,
    color: "white",
    mix: ["ice", "rain"],
  },
  {
    name: "wind",
    icon: BsWind,
    color: "windy",
    mix: ["air", "air"],
  },
  {
    name: "tornado",
    icon: GiTornado,
    color: "white",
    mix: ["wind", "wind"],
  },
  {
    name: "campfire",
    icon: GiCampfire,
    color: "burning",
    mix: ["fire", "fire"],
  },
  {
    name: "sun",
    icon: GiStripedSun,
    color: "sunny",
    mix: ["fire", "campfire"],
  },
  {
    name: "storm",
    icon: IoThunderstormSharp,
    color: "electric",
    mix: ["rain", "rain"],
  },
  {
    name: "tree",
    icon: FaTree,
    color: "trees",
    mix: ["earth", "rain"],
  },
  {
    name: "iron",
    icon: GiMetalBar,
    color: "white",
    mix: ["earth", "campfire"],
  },
  {
    name: "hatchet",
    icon: GiHatchet,
    color: "white",
    mix: ["iron", "stick"],
  },
  {
    name: "stick",
    icon: GiWoodStick,
    color: "white",
    mix: ["tree", "earth"],
  },
  {
    name: "kite",
    icon: GiKite,
    color: "white",
    mix: ["stick", "paper"],
  },
  {
    name: "woodpile",
    icon: GiWoodPile,
    color: "white",
    mix: ["hatchet", "tree"],
  },
  {
    name: "saw",
    icon: GiCrosscutSaw,
    color: "white",
    mix: ["hatchet", "hatchet"],
  },
  {
    name: "plank",
    icon: GiWoodBeam,
    color: "white",
    mix: ["saw", "woodpile"],
  },
  {
    name: "paper",
    icon: BsFileText,
    color: "white",
    mix: ["saw", "plank"],
  },
  {
    name: "book",
    icon: GiSpellBook,
    color: "white",
    mix: ["paper", "paper"],
  },
  {
    name: "cave",
    icon: GiMountainCave,
    color: "white",
    mix: ["mountain", "mountain"],
  },
  {
    name: "cabin",
    icon: GiWoodCabin,
    color: "white",
    mix: ["cave", "woodpile"],
  },
  {
    name: "house",
    icon: GiFamilyHouse,
    color: "white",
    mix: ["cabin", "plank"],
  },
  {
    name: "tools",
    icon: BsTools,
    color: "white",
    mix: ["book", "hatchet"],
  },
  {
    name: "glass",
    icon: GiGlassShot,
    color: "blue",
    mix: ["fire", "earth"],
  },
  {
    name: "magnify",
    icon: GiMagnifyingGlass,
    color: "glass",
    mix: ["glass", "stick"],
  },
  {
    name: "microscope",
    icon: GiMicroscope,
    color: "glass",
    mix: ["magnify", "tools"],
  },
  {
    name: "school",
    icon: FaSchool,
    color: "white",
    mix: ["book", "house"],
  },
  {
    name: "crop",
    icon: GiWheat,
    color: "white",
    mix: ["water", "earth"],
  },
  {
    name: "cloth",
    icon: GiClothes,
    color: "white",
    mix: ["crop", "crop"],
  },
  {
    name: "beam",
    icon: GiIBeam,
    color: "white",
    mix: ["iron", "campfire"],
  },
  {
    name: "building",
    icon: BsBuilding,
    color: "white",
    mix: ["beam", "house"],
  },
  {
    name: "factory",
    icon: GiFactory,
    color: "white",
    mix: ["building", "generator"],
  },
  {
    name: "generator",
    icon: GiLightningArc,
    color: "electric",
    mix: ["electricity", "science"],
  },
  {
    name: "science",
    icon: GiChemicalDrop,
    color: "white",
    mix: ["microscope", "water"],
  },
  {
    name: "pills",
    icon: GiMedicines,
    color: "white",
    mix: ["science", "bacteria"],
  },
  {
    name: "bacteria",
    icon: FaBacteria,
    color: "white",
    mix: ["science", "water"],
  },
  {
    name: "electricity",
    icon: GiPlug,
    color: "electric",
    mix: ["lightning", "science"],
  },
  {
    name: "lightning",
    icon: GiLightBulb,
    color: "electric",
    mix: ["kite", "storm"],
  },
  {
    name: "train",
    icon: GiSteamLocomotive,
    color: "white",
    mix: ["steam", "factory"],
  },
  {
    name: "caveman",
    icon: GiCaveman,
    color: "white",
    mix: ["cave", "fire"],
  },
  {
    name: "farmer",
    icon: GiFarmer,
    color: "white",
    mix: ["caveman", "crop"],
  },
  {
    name: "family",
    icon: MdOutlineFamilyRestroom,
    color: "white",
    mix: ["farmer", "farmer"],
  },
  {
    name: "wheel",
    icon: GiCartwheel,
    color: "white",
    mix: ["caveman", "tools"],
  },
  {
    name: "automobile",
    icon: GiRaceCar,
    color: "white",
    mix: ["wheel", "science"],
  },
  {
    name: "explosive",
    icon: GiMineExplosion,
    color: "white",
    mix: ["science", "fire"],
  },
  {
    name: "college",
    icon: FcLibrary,
    color: "white",
    mix: ["science", "school"],
  },
  {
    name: "scientist",
    icon: GiMadScientist,
    color: "white",
    mix: ["college", "farmer"],
  },
  {
    name: "atom",
    icon: GiMaterialsScience,
    color: "white",
    mix: ["scientist", "science"],
  },
  {
    name: "nuke",
    icon: GiMushroomCloud,
    color: "white",
    mix: ["atom", "explosive"],
  },
  {
    name: "death",
    icon: GiAngelOutfit,
    color: "white",
    mix: ["nuke", "family"],
  },
];
