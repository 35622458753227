import React, { useContext, useState } from "react";
import { EthosConnectProvider } from "ethos-connect";
import { setProvider, getUserNFTs, network } from "utils/sui";
import { findNFTInfo } from "allIngredients";

const ethosConfiguration = {
  apiKey: "keepsake-prod",
  walletAppUrl: "https://ethoswallet.xyz",
  network: network,
};

const ComboContext = React.createContext();

export function useCombo() {
  return useContext(ComboContext);
}

export function ComboProvider({ children }) {
  const [combo, setCombo] = useState([]);
  const [userIngredients, _setUserIngredients] = useState([]);
  const [userKiosk, setUserKiosk] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [newIngredient, setNewIngredient] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loading, setLoading] = useState(false);

  const setUserIngredients = (nfts) => {
    var seen = {};
    const unique = nfts.filter(function (item) {
      var k = item.name;
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });

    _setUserIngredients(unique);
  };

  const addUserIngredientFromNFT = (nft) => {
    const newUserIngredients = userIngredients.slice();
    newUserIngredients.push(findNFTInfo(nft));
    setUserIngredients(newUserIngredients);
  };

  const addUserIngredient = (nft) => {
    const newUserIngredients = userIngredients.slice();
    newUserIngredients.push(nft);
    setUserIngredients(newUserIngredients);
  };

  const onWalletConnected = async (provider, signer) => {
    if (loggingIn || !signer) return false;
    setLoggingIn(true);
    setLoading(true);
    let loggedin = await setProvider(provider, signer);
    if (loggedin) {
      let { kiosk, inventory } = await getUserNFTs(loggedin);
      inventory = findNFTInfo(inventory);
      setUserIngredients(inventory);
      setUserKiosk(kiosk);
      setLoading(false);
    }
  };

  return (
    <EthosConnectProvider
      ethosConfiguration={ethosConfiguration}
      onWalletConnected={({ provider, signer }) => onWalletConnected(provider, signer)}
    >
      <ComboContext.Provider
        value={{
          combo,
          setCombo,
          overlay,
          setOverlay,
          newIngredient,
          setNewIngredient,
          userIngredients,
          userKiosk,
          setUserKiosk,
          setUserIngredients,
          addUserIngredient,
          addUserIngredientFromNFT,
          loading,
          setLoading,
        }}
      >
        {children}
      </ComboContext.Provider>
    </EthosConnectProvider>
  );
}
