import { useState } from "react";
import { ComboProvider } from "components/utils/ComboProvider";
import ComboContainer from "./components/ComboContainer";
import Ingredients from "./components/Ingredients";
// import IngredientsCount from "./components/IngredientsCount";
import StartGameOverlay from "./components/StartGameOverlay";
import Sidebar from "./components/Sidebar";
import MintOverlay from "components/MintOverlay";

function App() {
  const [modal, setModal] = useState(true);

  return (
    <div className="App">
      <div className="background">
        <ComboProvider>
          <MintOverlay />
          <main>
            <StartGameOverlay modal={modal} setModal={setModal} />
            {!modal && (
              <>
                {/* <IngredientsCount /> */}
                <Sidebar />
                <ComboContainer />
                <Ingredients />
              </>
            )}
          </main>
        </ComboProvider>
      </div>
    </div>
  );
}

export default App;
