import { fetchKiosk, getOwnedKiosks, queryTransferPolicy } from "@mysten/kiosk";
import {
  JsonRpcProvider,
  SUI_CLOCK_OBJECT_ID,
  SUI_FRAMEWORK_ADDRESS,
} from "@mysten/sui.js";
import { ethos } from "ethos-connect";
import ingredientAddresses from "utils/ingredientAddresses";
import { makeTransactionBlock } from "./txnbuilder";

export const network = "https://fullnode.testnet.sui.io:443";
let provider = new JsonRpcProvider({ fullnode: network, websocket: network });
let signer = false;
let userAddress = false;
export const packageObjectId =
  "0x352454e4be57a80492cb5e5f3097d78ffd1b5a7f24b33b9ace460bc548743c74";
const module = "alchemy";
const baseData = "0xb65446fca91913d9dcd90a46aaa2ad3df1048abf5b02b6f113c08b954fcd6361";

const policy = "0x02c56bfc81c866244a8430a79e07487e587d8d9ac49576fc2da32e26241b36ea";

let defaultDetails = {
  kind: "moveCall",
  data: {
    module,
    packageObjectId,
    typeArguments: [],
    gasBudget: 2000,
  },
};

export const starters = ingredientAddresses.slice(0, 4).map((i) => i.address);
const type = `${packageObjectId}::${module}::ELEMENT`;

export const setProvider = async (newProvider, newSigner) => {
  // const policy = await queryTransferPolicy(provider, type);
  // console.log(policy);

  try {
    userAddress = await newSigner.getAddress();
    // provider = newProvider;
    signer = newSigner;
    // ethos.dripSui({ address: newAddress });
    return userAddress;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const formatSuiItem = ({ data }) => {
  if (data) {
    let { content, objectId: id, owner, display, type } = data;
    return {
      owner: owner?.ObjectOwner || owner?.AddressOwner || "Shared",
      data: content?.fields || content,
      display: display,
      id,
      type,
      // packageObjectId: reference?.objectId,
    };
  }
  return false;
};

export const getObjectInfo = (objectId) =>
  provider
    .getObject({ id: objectId, options: { showDisplay: true, showContent: true } })
    .then((data) => formatSuiItem(data));

export const getObjectsInfo = (objectIds, options = { showContent: true }) =>
  provider
    .multiGetObjects({
      ids: objectIds,
      options,
    })
    .then((info) => {
      return info.map((data) => formatSuiItem(data));
    });

export const getAddressForElement = (elementName) =>
  ingredientAddresses.find((e) => (e.name = elementName));

export const getUserNFTs = async (address, user_kiosk) => {
  let kiosk = user_kiosk || localStorage.getItem("kiosk");

  if (kiosk.owner !== address) {
    kiosk = user_kiosk || false;
  }

  if (!user_kiosk) {
    const kiosksData = await getOwnedKiosks(provider, address);
    let kiosks = [];
    kiosksData.kioskOwnerCaps.forEach((cap) => {
      kiosks.push({ id: cap.kioskId, cap: cap.objectId, version: cap.version });
    });

    kiosks = kiosks.sort((a, b) => {
      return a.objectId > b.objectId ? 1 : -1;
    });
    kiosk = kiosks[0];
    if (kiosk) {
      kiosk.owner = address;
    }
  }
  if (!kiosk) {
    return { suiObjects: [] };
  }
  let items = await fetchKiosk(provider, kiosk.id, {}, {}); // {cursor, limit}

  let suiObjects = await getObjectsInfo(items.data.itemIds);
  let inventory = suiObjects
    .filter((a) => a?.data?.archetype)
    .map(({ data: { id, name, url, description, archetype } }) => {
      const formatted = { id: id.id, name, url, description, data_id: archetype };
      // delete formatted.data.id;
      return formatted;
    });

  // TODO: write kiosk to localstorage or something to better ensure it uses the right one
  localStorage.setItem("kiosk", JSON.stringify(kiosk));

  return { kiosk, inventory };
};

export const formatTransaction = (tx) => {
  const response = {};
  if (tx.EffectsCert) {
    response.effects = tx.EffectsCert.effects.effects;
    response.created = tx.EffectsCert.effects.effects.created;
    response.certificate = tx.EffectsCert.certificate;
    response.status = tx.EffectsCert.effects.effects.status.status;
  } else {
    response.effects = tx.effects;
    response.created = tx.effects.created;
    response.certificate = tx.certificate;
    response.status = tx.effects.status.status;
  }
  return response;
};

const promiseTransact = async (txArray, payment) => {
  let transactions = Array.isArray(txArray) ? txArray : [txArray];

  // let allArguments = [];
  transactions.forEach((tx) => {
    // if (tx.arguments) {
    //   allArguments = tx.arguments.filter((a) => isValidSuiObjectId(a));
    // }
    if (!tx.target && !tx.packageObjectId) {
      tx.packageObjectId = defaultDetails.packageObjectId;
      if (!tx.module) {
        tx.module = defaultDetails.module;
      }
    }
  });
  const txb = makeTransactionBlock(transactions, userAddress);
  txb.setSender(userAddress);

  // let gasBudget = Math.min(gasBalance, 1000000);
  if (payment) {
    txb.setGasPayment([txb.object(payment)]);
  }

  try {
    // ensure transaction actually works before trying it with a wallet
    let res = await txb.build({ provider });
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }

  return new Promise((resolve, reject) => {
    ethos
      .signTransactionBlock({
        signer,
        transactionInput: {
          transactionBlock: txb,
          acccount: userAddress,
        },
      })
      .then((signedTx) =>
        provider
          .executeTransactionBlock({
            transactionBlock: signedTx.transactionBlockBytes,
            signature: signedTx.signature,
            options: {
              showEffects: true,
            },
            requestType: "WaitForLocalExecution",
          })
          .then((tx) => {
            if (tx?.effects?.status?.status === "success") {
              resolve(formatTransaction(tx));
            } else if (tx?.effects?.status?.error) {
              reject(tx?.effects?.status?.error);
            }
            reject(tx);
          })
      )
      .catch((e) => {
        console.log(e);
        reject(e);
      });
  });
};

export const combine = async (kiosk, a, b, c) => {
  if (a !== b) {
    return promiseTransact([
      // {
      //   target: `${packageObjectId}::${module}::verify_combination`,
      //   arguments: [baseData, a, b, c],
      //   types: ["object", "address", "address", "address"],
      // },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::borrow_val`,
        typeArguments: [type],
        arguments: [kiosk.id, kiosk.cap, a],
        types: ["object", "object", "address"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::borrow_val`,
        typeArguments: [type],
        arguments: [kiosk.id, kiosk.cap, b],
        types: ["object", "object", "address"],
      },
      {
        target: `${packageObjectId}::${module}::combine`,
        arguments: [
          baseData,
          { txIndex: 0, index: 0 },
          { txIndex: 1, index: 0 },
          c,
          SUI_CLOCK_OBJECT_ID,
        ],
        types: ["object", "object", "object", "object", "object"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
        typeArguments: [type],
        arguments: [kiosk.id, kiosk.cap, policy, { txIndex: 2, index: 0 }],
        types: ["object", "object", "object", "object"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::return_val`,
        typeArguments: [type],
        arguments: [
          kiosk.id,
          {
            txIndex: 0,
            index: 0,
          },
          {
            txIndex: 0,
            index: 1,
          },
        ],
        types: ["object", "object", "address"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::return_val`,
        typeArguments: [type],
        arguments: [
          kiosk.id,
          {
            txIndex: 1,
            index: 0,
          },
          {
            txIndex: 1,
            index: 1,
          },
        ],
        types: ["object", "object", "object"],
      },
    ]);
  } else {
    return promiseTransact([
      // {
      //   target: `${packageObjectId}::${module}::verify_combination`,
      //   arguments: [baseData, a, b, c],
      //   types: ["object", "address", "address", "address"],
      // },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::borrow_val`,
        typeArguments: [type],
        arguments: [kiosk.id, kiosk.cap, a],
        types: ["object", "object", "address"],
      },
      {
        target: `${packageObjectId}::${module}::combine_with_itself`,
        arguments: [baseData, { txIndex: 0, index: 0 }, c, SUI_CLOCK_OBJECT_ID],
        types: ["object", "object", "object", "object"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
        typeArguments: [type],
        arguments: [kiosk.id, kiosk.cap, policy, { txIndex: 1, index: 0 }],
        types: ["object", "object", "object", "object"],
      },
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::return_val`,
        typeArguments: [type],
        arguments: [
          kiosk.id,
          {
            txIndex: 0,
            index: 0,
          },
          {
            txIndex: 0,
            index: 1,
          },
        ],
        types: ["object", "object", "address"],
      },
    ]);
  }
};

export const mintStarters = async (userKiosk) => {
  let kiosk = userKiosk?.id;
  let cap = userKiosk?.cap;
  let txns = [];

  txns = txns.concat([
    {
      target: `${packageObjectId}::${module}::mint_starter`,
      arguments: [starters[0], SUI_CLOCK_OBJECT_ID, baseData],
      types: ["address", "object", "object"],
    },
    {
      target: `${packageObjectId}::${module}::mint_starter`,
      arguments: [starters[1], SUI_CLOCK_OBJECT_ID, baseData],
      types: ["address", "object", "object"],
    },
    {
      target: `${packageObjectId}::${module}::mint_starter`,
      arguments: [starters[2], SUI_CLOCK_OBJECT_ID, baseData],
      types: ["address", "object", "object"],
    },
    {
      target: `${packageObjectId}::${module}::mint_starter`,
      arguments: [starters[3], SUI_CLOCK_OBJECT_ID, baseData],
      types: ["address", "object", "object"],
    },
  ]);

  if (!userKiosk) {
    txns.push({
      target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::new`,
      typeArguments: [],
      arguments: [],
      types: [],
    });
    kiosk = { txIndex: 4, index: 0 };
    cap = { txIndex: 4, index: 1 };
  }

  txns = txns.concat([
    {
      target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
      typeArguments: [type],
      arguments: [kiosk, cap, policy, { txIndex: 0, index: 0 }],
      types: ["object", "object", "object", "object"],
    },
    {
      target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
      typeArguments: [type],
      arguments: [kiosk, cap, policy, { txIndex: 1, index: 0 }],
      types: ["object", "object", "object", "object"],
    },
    {
      target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
      typeArguments: [type],
      arguments: [kiosk, cap, policy, { txIndex: 2, index: 0 }],
      types: ["object", "object", "object", "object"],
    },
    {
      target: `${SUI_FRAMEWORK_ADDRESS}::kiosk::lock`,
      typeArguments: [type],
      arguments: [kiosk, cap, policy, { txIndex: 3, index: 0 }],
      types: ["object", "object", "object", "object"],
    },
  ]);

  if (!userKiosk) {
    txns = txns.concat([
      {
        target: `${SUI_FRAMEWORK_ADDRESS}::transfer::public_share_object`,
        typeArguments: [`${SUI_FRAMEWORK_ADDRESS}::kiosk::Kiosk`],
        arguments: [kiosk],
        types: ["object"],
      },
      {
        type: "transferObjects",
        object: cap,
        to: userAddress,
      },
    ]);
  }

  return promiseTransact(txns);
};
